<template>
	<div class="container">
		<el-form label-width="120px" :action="api.RnPanelUploadUrl">
			<el-form-item label="文件格式提示:">

			</el-form-item>
			<el-form-item label="面板包:">
				<el-upload :on-change="readPanelFile" accept=".zip" action="" ref="uploadForm" :auto-upload="false" :show-file-list="false">
					<el-button slot="trigger" size="small" type="primary">选取面板包</el-button>
					<span> {{panel_file.name}}</span>
				</el-upload>
			</el-form-item>
			<!-- 			<el-form-item label="JSON配置文件:">
				<el-upload :on-change="readPanelJson" action="" accept=".json" :auto-upload="false" :show-file-list="false">
					<template #trigger>
						<el-button slot="trigger" size="small" type="primary">选取JSON配置文件</el-button>
					</template>
					<el-tag class="ml-3" type="danger" @click="clickTips">文件内容提示！</el-tag>
				</el-upload>
			</el-form-item> -->
			<el-form-item label="操作系统:">
				<el-select v-model="uploadForm.os_type" disabled placeholder="请选择操作系统">
					<el-option label="请选择" :value="0"></el-option>
					<el-option label="安卓" :value="1"></el-option>
					<el-option label="IOS" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="UIID/面板名称:">
				<el-input :disabled="true" v-model="uploadForm.panel_name"></el-input>
			</el-form-item>
			<el-form-item label="面板版本:">
				<el-input :disabled="true" v-model="uploadForm.version"></el-input>
			</el-form-item>
			<el-form-item label="APP最小版本:">
				<el-input :disabled="true" v-model="uploadForm.app_version"></el-input>
			</el-form-item>
			<el-form-item label="文件MD5:">
				<el-input :disabled="true" v-model="uploadForm.md5"></el-input>
			</el-form-item>
			<el-form-item label="JS Bundle MD5:">
				<el-input :disabled="true" v-model="uploadForm.js_bundle_md5"></el-input>
			</el-form-item>
			<el-form-item label="moduleName:">
				<el-input :disabled="true" v-model="uploadForm.moduleName"></el-input>
			</el-form-item>
			<el-form-item label="自定义内容:">
				<el-input v-model="uploadForm.content"></el-input>
			</el-form-item>
			<el-form-item label="更新说明:">
				<el-input v-model="uploadForm.notes" autosize type="textarea"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button>取 消</el-button>
			<el-button type="primary" @click="onSubmit">确 定</el-button>
		</div>
	</div>

	<el-drawer v-model="drawer" title="JSON配置文件内容说明" direction="rtl">
		<span>Hi, there!</span>
	</el-drawer>
</template>

<script>
	import JSZip from "jszip";
	import axios from 'axios'
	export default {
		components: {},
		name: 'RnUpload',
		mixins: [],
		data() {
			return {
				uploadForm: {
					rows: 10,
					page: 1,
					total: 0,
					panel_type: "",
				},
				panel_file: {},
				drawer: false
			}
		},
		mounted() {
			this.uploadForm.panel_type = this.$route.query.panel_type;
		},
		methods: {
			onSubmit: function() {
				let formData = new FormData()
				Object.keys(this.uploadForm).forEach(key => {
					formData.append(key, this.uploadForm[key])
				})
				formData.append("file", this.panel_file);
				let timestamp = new Date().getTime()
				let header = {
					'token': JSON.parse(localStorage.getItem('user_info'))["token"],
					'client_time': parseInt(timestamp / 1000),
					'uid': JSON.parse(localStorage.getItem('user_info'))["id"],
					'request_id': timestamp,
					'CA-KEYS': 'client_time,request_id,token,uid',
					region: localStorage.getItem('region_change')
				}
				header.signature = this.signature(timestamp, {
					method: 'POST',
					data: "",
					url: this.uri.RnPanelUploadUrl
				}, header);
				fetch(this.uri.RnPanelUploadUrl, {
					method: 'POST',
					body: formData,
					headers: header,
				}).then(res => {
					if (res.status != 200) {
						this.$message({
							message: '服务器请求失败',
							type: 'error'
						});
					}
					return res.json();
				}).then(res => {
					if (res != null) {
						if (res.code == 200) {
							this.$message({
								message: '上传成功',
								type: 'success'
							});
							this.uploadForm = {
								panel_id: "",
								panel_type: this.$route.query.panel_type,
								panel_name: "",
								os_type: 0,
								version: "",
								app_version: "",
								content: "",
								md5: "",
								js_bundle_md5: "",
								notes: "",
							}

							return;
						}
						this.$message.error(res.msg);
					}
				})
			},
			readPanelJson: function(obj) {
				try {
					const dataJson = JSON.parse(obj);
					var keys = ["uiid", "version", "appPlatformVersion", "md5", "jsbundlemd5", "moduleName"];
					keys.forEach(function(key) {
						if (!dataJson.hasOwnProperty(key)) {
							this.$message.error(); // 这是为了抛出错误
							return
						}
					})
					this.uploadForm.panel_name = dataJson["uiid"];
					this.uploadForm.version = dataJson["version"];
					this.uploadForm.app_version = dataJson["appPlatformVersion"];
					this.uploadForm.md5 = dataJson["md5"];
					this.uploadForm.js_bundle_md5 = dataJson["jsbundlemd5"];
					this.uploadForm.moduleName = dataJson["moduleName"];

				} catch (u) {
					this.uploadForm = {
						panel_id: "",
						panel_name: "",
						os_type: 0,
						version: "",
						app_version: "",
						content: "",
						md5: "",
						js_bundle_md5: "",
						panel_type: this.$route.query.panel_type,
						notes: "",
					}
					this.$message.error("文件内容不完整或错误");
				}
			},
			readPanelFile: function(file) {
				if (file.name.lastIndexOf('_ios.zip') != -1) {
					this.uploadForm.os_type = 2;
				}

				if (file.name.lastIndexOf('_android.zip') != -1) {
					this.uploadForm.os_type = 1;
				}

				if (this.uploadForm.os_type == 0 || this.uploadForm.os_type == undefined) {
					this.$message.error("文件格式错误");
					return;
				}
				let that = this;
				JSZip.loadAsync(file.raw).then(function(zip) {
					for (let key in zip.files) { // 循环遍历文件夹下的文件
						if (!zip.files[key].dir) {
							if (zip.files[key].name.lastIndexOf('.json') != -1) {
								zip.file(zip.files[key].name).async('string').then(res => {
									that.readPanelJson(res);
								})
							}

							if (zip.files[key].name.lastIndexOf('.zip') != -1) {
								//that.panel_file = zip.files[key];
								zip.file(zip.files[key].name).async('blob').then((blob)=>{
									that.panel_file = new window.File([blob],zip.files[key].name,{type:blob.type});
								})
							}
						}
					}
				})
			},

			clickTips: function() {
				this.drawer = true;
			}
		},
	}
</script>


<style>
	.ml-3 {
		margin-left: 0.75rem;
	}
</style>